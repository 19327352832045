import { snotify } from '@/plugins/vueSnotify';
import { SnotifyPosition } from 'vue-snotify';

export default class Toast {
  static success(message, title = '', callback = null) {
    this.fireNotification(message, title, 'success', callback);
  }

  static info(message, title = '', callback = null) {
    this.fireNotification(message, title, 'info', callback);

  }

  static warning(message, title = '', callback = null) {
    this.fireNotification(message, title, 'warning', callback);
  }

  static error(message, title = '', callback = null) {
    this.fireNotification(message, title, 'error', callback);
  }

  static notifyError(message, title = '', callback = null) {

    let config = {
      position: SnotifyPosition.centerTop,
      showProgressBar: false
    };

    this.fireNotification(message, title, 'error', callback, config);
  }

  static notifySuccess(message, title = '', callback = null) {

    let config = {
      position: SnotifyPosition.centerTop,
      showProgressBar: false
    };

    this.fireNotification(message, title, 'success', callback, config);
  }


  static fireNotification(message, title, type, callback = null, config = {}) {
    let notification = snotify[type](message, title, config);

    notification.on('beforeHide', c => {
      if (typeof callback === 'function') {
        callback(c);
      }
    });

  }
}
